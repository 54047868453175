@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=League+Spartan&family=Montserrat&family=Permanent+Marker&display=swap');

body, html {
  margin: 0;
  max-width: 100vw;
  font-family: 'League Spartan';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #141a17;
  background-color: #a0b49c;
}

::-webkit-scrollbar {
  width: 8px;
  height: 0px;
  background: #141a17;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #a0b49c;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  width: 12px;
  height: 0px;
}